import React from "react"

import Index from "../components/index"
import SEO from "../components/other/seo"
import Container from "../components/layout/container";
import HighlightedParagraph from "../components/elements/highlighted-paragraph";
import MicroSure from "../components/elements/micro-sure";
import {
  TextWithImageWrap,
  TextWithImageText,
  TextWithImageImage,
} from "../components/elements/text-with-image";
import Technology1Image from "../components/images/technology-1";
import Technology2Image from "../components/images/technology-2";

const TechnologyPage = () => (
  <Index>
    <SEO title="Technology" />
    <Container id="product-overview-banner">
      <h1>Technology</h1>
    </Container>
    <Container>
      <HighlightedParagraph>
        <b>Strategia Project Management Inc.</b> is a group of seasoned<br />
        healthcare professionals who are devoted to researching<br />
        and developing critical solutions that respond<br />
        to global healthcare crisis.
      </HighlightedParagraph>
      <TextWithImageWrap>
        <TextWithImageText>
          <h2>Revolutionary Defense Barrier</h2>
          <p>This team of physicians have a combined experience of over 160 years in health care. Under the leadership of neurosurgeon and founder, Dr. Erwin Lo, Strategia has developed a revolutionary soluble antimicrobial solution that kills harmful germs through a “mechanical kill” method. When applied to any multiple diverse surface, the solution dries and leaves behind a nanomolecular silica dioxide crystalline layer. This layer creates a layer of nanoscopic killing spikes that puncture the cell walls of harmful germs. Unlike other products that kill germs through a chemical kill using a combination of toxic chemicals, the <MicroSure /> solution kills germs when applied and continues killing them for up to 8 hours after application.</p>
        </TextWithImageText>
        <TextWithImageImage>
          <Technology1Image />
        </TextWithImageImage>
      </TextWithImageWrap>
      <TextWithImageWrap>
        <TextWithImageImage>
          <Technology2Image />
        </TextWithImageImage>
        <TextWithImageText>
          <h2>Safe and Effective</h2>
          <p>Strategia’s products undergo a strict clearance process including testing in a Bio Safety Level 4 laboratories and meeting all FDA guidelines. The effectiveness of the surface protectant has been tested and proved effective by the University of Liverpool and the University of Georgia. The <MicroSure /> hand sanitizer, disinfectant, and laundry detergent kills 99.9% germs like SARs on contact, protecting you and your world. </p>
        </TextWithImageText>
      </TextWithImageWrap>
    </Container>
  </Index>
)

export default TechnologyPage
