import React from "react"

export const TextWithImageWrap = ({ children }) => (
  <div className="text-with-image">
    { children }
  </div>
)

export const TextWithImageText = ({ children }) => (
  <div className="text">
    { children }
  </div>
)

export const TextWithImageImage = ({ children }) => (
  <div className="image">
    { children }
  </div>
)
